<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="false" :show-top-header="false" :logo-dark="true"
        buy-button-class="btn-default btn-small">
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"
                            subtitle="Contact Form"
                            :title="$t('contactType')"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="headphones" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">{{ $t('contactNum') }}</h4>
                                            <p><a href="tel:+444 555 666 777">+86 180 0502 6242</a></p>
                                            <p><a href="tel:+222 222 222 333">0592-5910812</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">{{ $t('contactEmail') }}</h4>
                                            <p><a href="mailto:admin@gmail.com">info@fitshow.com</a></p>
                                            <p><a href="mailto:example@gmail.com">support@fitshow.com</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="map-pin" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">{{ $t('contactAddr') }}</h4>
                                            <p>{{$t('address')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :style="{height: emptyHeight+'px'}"></div>
                <!-- <div class="row mt--40 row--15">
                    <div class="col-lg-7">
                        <ContactForm/>
                    </div>
                    <div class="col-lg-5 mt_md--30 mt_sm--30">
                        <GoogleMap/>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- End Contact Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Icon from "../components/icon/Icon";
    import GoogleMap from "../components/elements/contact/GoogleMap";
    import ContactForm from "../components/elements/contact/ContactForm";

    export default {
        name: 'ContactPage',
        components: {ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout},
        data(){
            return {
                emptyHeight: 0,
                pageHeight: 0
            }
        },
        mounted() {
            var header = this.$el.querySelector(".rn-header");
            var content = this.$el.querySelector(".rwt-contact-area");
            var footer = this.$el.querySelector(".copyright-area");
            this.pageHeight = header.offsetHeight+content.offsetHeight+footer.offsetHeight;
            let height = window.innerHeight - header.offsetHeight-content.offsetHeight-footer.offsetHeight;
            this.emptyHeight =  height > 0 ? height:0;
            window.addEventListener('resize', this.browserChange);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.browserChange)
        },
        methods: {
            browserChange() {
                let height = window.innerHeight - this.pageHeight;
                this.emptyHeight =  height > 0 ? height:0;
            }
        },
    }
</script>