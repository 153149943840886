<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false"
        buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-650 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/bg-image-3.0d2d668d.jpg`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">{{ $t('aboutFs') }}</h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="200">
                                {{ $t('aboutFsDesc') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gapBottom pt--100">
            <div class="container">
                <ServiceOne
                    :serviceList = "seviceData"
                    service-style="service__style--1 icon-circle-style"
                    text-align="center"
                    icon-size="39"
                />
            </div>
        </div>
        <!-- End Service Area -->

        <div class="rwt-split-area">
            <div class="wrapper">
                <Split :split-data="splitData" :split-style="3"/>
            </div>
        </div>
       
        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" :title="$t('ourProducts')" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15" data-aos="fade-up">
                    <template v-for="(portfolio, index) in portfolioData">
                        <div :class="`col-lg-4 col-md-6 col-12 mt--30 portfolio aos-init aos-animate`" data-aos="fade-up">

                            <div class="rwt-card" data-aos="fade-up">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <figure class="card-image">
                                            <img class="img-fluid" :src="portfolio.portfolioImage" alt="Portfolio-01" />
                                        </figure>
                                    </div>
                                    <div class="content">
                                        <h5 class="title mb--10">
                                            {{ portfolio.title }}
                                        </h5>
                                        <span class="b2">{{ portfolio.category }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <Separator/>

    
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import ServiceOne from '../components/elements/service/ServiceOne'
    // import Portfolio from '../components/elements/portfolio/Portfolio'
    // import Timeline from '../components/elements/timeline/Timeline'
    // import Video from '../components/elements/video/Video'
    // import Testimonial from '../components/elements/testimonial/Testimonial'
    // import BlogPostMixin from '../mixins/BlogPostMixin'
    // import BlogPost from '../components/blog/BlogPost'
    // import CallToAction from '../components/elements/callToAction/CallToAction'
    import Split from '../components/elements/split/Split'

    export default {
        name: 'BusinessConsulting',
        components: {
            ServiceOne,
            // CallToAction,
            // BlogPost,
            // Testimonial,
            // Video,
            // Timeline,
            // Portfolio,
            SectionTitle,
            Separator,
            Icon,
            Layout,
            Split
        },
        // mixins: [BlogPostMixin],
        data() {
            return {
            }
        },
        computed:{
            seviceData() {
                return [
                    {
                        icon: 'activity',
                        title: this.$t('ourVision'),
                        description: this.$t('ourVisionDesc'),
                    },
                    {
                        icon: 'cast',
                        title: this.$t('ourMission'),
                        description: this.$t('ourMissionDesc'),
                    },
                    {
                        icon: 'map',
                        title: this.$t('ourValues'),
                        description: this.$t('ourValuesDesc'),
                    },
                ]
            },
            portfolioData() {
                return [
                        {
                            id: 1,
                            portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            title: this.$t('bluetoothModule'),
                            category: this.$t('bluetoothModuleDetail'),
                        },
                        {
                            id: 2,
                            portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            title: this.$t('smartKnob'),
                            category: this.$t('smartKnobDetail'),
                        },
                        {
                            id: 3,
                            portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                            title: this.$t('wirelessController'),
                            category: this.$t('wirelessControllerDetail'),
                        },
                        {
                            id: 4,
                            portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                            title: this.$t('sisplaySystem'),
                            category: this.$t('sisplaySystemDetail'),
                        },
                        {
                            id: 5,
                            portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                            title: this.$t('heartRateMonitor'),
                            category: this.$t('heartRateMonitorDetail'),
                        },
                        {
                            id: 6,
                            portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                            title: this.$t('resistanceRegulator'),
                            category: this.$t('resistanceRegulatorDetail'),
                        },

                ]
            },
            splitData() {
                return {
                    image: 'split-03.1dba12e3.jpg',
                    title: this.$t('globalEquipmentProvider'),
                    description: this.$t('globalEquipmentProviderDesc'),
                    counterData: [
                        {
                            number: 5000000,
                            title: this.$t('smartEnabledDevices'),
                        },
                        {
                            number: 280,
                            title: this.$t('oemPartners'),
                        },
                        {
                            number: 220,
                            title: this.$t('brandPartners'),
                        },
                        {
                            number: 120,
                            title: this.$t('regionalDistribution'),
                        }
                    ]
                }
            }
        }
    }
</script>