<template>
    <!-- <div><img class="logo-light" :src="lightLogo" alt="Corporate Logo"/></div> -->
    <h1  class="logo" title="运动秀,FitShow">
        <router-link :to="url">
            <!-- <img class="logo-light" :src="darkLogo" alt="Corporate Logo"/> -->
        </router-link>
  </h1>
    
    
    
</template>

<script>
    export default {
        name: 'Logo',
        
        props: {
            url: {
                type: String,
                default: '/'
            },
            lightLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/logo.png`)
                }
            },
            darkLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/logo-dark.png`)
                }
            },
            isShow: {
                type: Boolean,
                default: false
            },
            show: {
                type: Function ,
                default: function () {
                    var obj = document.getElementsByTagName('div');
                        console.log(obj);
                }
            },
        }
    }

</script>