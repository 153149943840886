import axios from 'axios';
import apiArr from './api'; // 用户请求集合
import { Message } from 'element-ui';

// 创建axios实例
const service = axios.create({
  baseURL: "https://fl.fitshow.com",
  timeout: 15000,
});

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  //  promise动态添加请求头，加完之后再return出config继续请求
//   const headerHandlers = (axiosConfig.headerHandlers || []).map(handler => {
//     return handler(config).then((mixHeaders) => Object.assign(config.headers || {}, mixHeaders))
//   });
//   return Promise.all(headerHandlers).then(() => config);

  config.params = {
    ...config.params
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  Message.error(error.message)
  return Promise.reject(error);
});

// 创建请求

const createRequest = () => {
  const hostApi = {};
  apiArr.forEach(api => {
    hostApi[api.name] = (params) => {
      const method = api.method || "POST";
      const requestParams = {};
    //   const requestParams = USE_DATA_METHODS.includes(method) ? { data: qs.stringify(params) } : { params: params };
      if (method == "GET") {
        requestParams.params = params;
      } else {
        requestParams.data = params
      }

      return service({
        url: api.path,
        method,
        headers: {
          ...api.headers
        },
        ...requestParams,
      })
    }
  });
  return hostApi;
}

const apis = createRequest();

export default apis;
