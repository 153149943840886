<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-8 col-sm-12 col-12">
                    <div class="copyright-left">
                        <ul class="ft-menu link-hover">
                            <li>
                                <router-link to="/article?key=privacy" target="_blank">{{$t('privacyPolicy')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/article?key=terms" target="_blank">{{$t('termsAndCondition')}}</router-link>
                            </li>
                            <!-- <li>
                                <router-link to="/contact">{{$t('contactUs')}}</router-link>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-6 col-sm-12 col-12">
                    <div class="copyright-right text-center text-lg-end">
                        <p class="copyright-text">© Fitshow {{ new Date().getFullYear() }} 运动秀（厦门）信息科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #656767;">闽ICP备19021571号</a> 闽公网安备35021102000648号</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>