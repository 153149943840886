<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="false" :show-top-header="false" :logo-dark="true"
        buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-3 height-850">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title theme-gradient" data-aos="fade-up" data-aos-delay="100" style="font-size: 100px;">
                                {{ $t("fitshowApp") }}
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="130">
                                {{ $t('fitshowDesc') }}
                            </p>
                        </div>
                    </div>
                    <div class="order-1 order-lg-2 col-lg-5" data-aos="fade-up" data-aos-delay="100">
                        <div>
                            <img src="../assets/images/banenr-image-02.38800a76.png" alt="fitshow,运动秀"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
        <!-- Start Timeline Area -->
        <Separator/>
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Timeline"
                            :title="$t('aboutApp')"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 mt--50">
                        <Timeline :timeline-data="timelineData" :timeline-style="4"/>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>

        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" :title="$t('featuresIntro')" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15" data-aos="fade-up">
                    <template v-for="(portfolio, index) in portfolioData">
                        <div :class="`col-lg-4 col-md-6 col-12 mt--30 portfolio aos-init aos-animate`" data-aos="fade-up">

                            <div class="rwt-card" data-aos="fade-up">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <figure class="card-image">
                                            <img class="img-fluid" :src="portfolio.portfolioImage" alt="Portfolio-01" />
                                        </figure>
                                    </div>
                                    <div class="content">
                                        <h5 class="title mb--10">
                                            {{ $t(portfolio.title) }}
                                        </h5>
                                        <span class="b2">{{ $t(portfolio.category) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
   
        <div class="rwt-callto-action-area rn-section-gapBottom">
            <div class="wrapper">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"
                            :title="$t('customeApp')"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <CallToAction
                    :style-type="5"
                    :title="$t('customeAppTitle')"
                    :subtitle="$t('customeAppDesc')"
                    :btn-text="$t('learnMore')"
                />
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Timeline from '../components/elements/timeline/Timeline'
    import Accordion from '../components/elements/accordion/Accordion'
    import Pricing from '../components/elements/pricing/Pricing'
    import CallToAction from "../components/elements/callToAction/CallToAction";

    export default {
        name: 'Business',
        components: { Pricing, Accordion, Timeline, SectionTitle, Separator, Icon, Layout, CallToAction},
        data() {
            return {
            }
        },
        computed: {
            timelineData() {
                return [
                    {
                        id: '1',
                        title: this.$t('supportEquipment'),
                        description: this.$t('supportEquipmentDesc'),
                    },
                    {
                        id: '2',
                        title: this.$t('supportLang'),
                        description: this.$t('supportLangDesc'),
                    },
                    {
                        id: '3',
                        title: this.$t('features'),
                        description: this.$t('featuresDesc'),
                    },
                    {
                        id: '4',
                        title: this.$t('price'),
                        description: this.$t('priceDesc'),
                    },
                ]
            },
            portfolioData() {
                return [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio-01.5dee8713.jpg`),
                        title: this.$t('quickStart'),
                        category: this.$t('quickStartDesc'),
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio-02.5dee8713.jpg`),
                        title: this.$t('structured'),
                        category: this.$t('structuredDesc'),
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio-03.5dee8713.jpg`),
                        title: this.$t('customWorkout'),
                        category: this.$t('customWorkoutDesc'),
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio-04.5dee8713.jpg`),
                        title: this.$t('sceneWorkout'),
                        category: this.$t('sceneWorkoutDesc'),
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio-05.5dee8713.jpg`),
                        title: this.$t('planWorkout'),
                        category: this.$t('planWorkoutDesc'),
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio-06.5dee8713.jpg`),
                        title: this.$t('healthWorkout'),
                        category: this.$t('healthWorkoutDesc'),
                    },
                ]
            },
        }
    }
</script>