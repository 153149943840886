<template>
    <div class="article">
        <h1>{{title}}</h1>
        <div v-html="content"></div>
    </div>
</template>

<script>
export default{
    data(){
        return {
            title: "",
            content: ""
        }
    },
    mounted() {
        if (this.$route.query["key"]) {
            this.getArticle(this.$route.query["key"]);
        }
        
    },
    methods: {
        async getArticle(key) {
            console.log(this.$i18n.locale)
            let lang = "en";
            if (this.$i18n.locale == "zh") {
                lang = "zh_cn"
            }
            const res = await this.apis.getArticle({"identify": key, "lang": lang});
            if (res.code == 1 && res.data.id > 0) {
                this.title = res.data.title
                this.content = res.data.content
                document.title = res.data.title;
            }
        }
    }
}
</script>
<style scoped>
.article{
    padding: 20px 30px;
}
h1{
    font-size: 30px;
}
/deep/h2{
    font-size: 20px;
}
</style>