<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="false" :show-top-header="false" :logo-dark="true"
        buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-8 height-650">
            <div class="single-slide">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="inner text-center">
                                <h1 class="title" data-aos="fade-up" data-aos-delay="150">
                                   {{ $t('embeddedApp') }}
                                </h1>
                                <p class="description" data-aos="fade-up" data-aos-delay="200">
                                    {{ $t('embeddedAppDesc') }}
                                </p>
                                <div class="button-group mt--30" data-aos="fade-up" data-aos-delay="250">
                                    <router-link class="btn-default btn-large round"
                                       to="/fitshowApp">
                                        {{ $t('aboutFsApp') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Separator/>
        <div class="our-counterup-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail" data-aos="fade-up">
                            <img class="radius w-100" :src="require('@/assets/images/'+lang+'/embedded1.png')" alt="Images" height="100px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>
        <div class="our-counterup-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail" data-aos="fade-up">
                            <img class="radius w-100" :src="require('@/assets/images/'+lang+'/embedded2.png')" alt="Images" height="100px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>
        <div class="our-counterup-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail" data-aos="fade-up">
                            <img class="radius w-100" :src="require('@/assets/images/'+lang+'/embedded3.png')" alt="Images" height="100px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>
        <div class="our-counterup-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail" data-aos="fade-up">
                            <img class="radius w-100" :src="require('@/assets/images/'+lang+'/embedded4.png')" alt="Images" height="100px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>
        <div class="our-counterup-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail" data-aos="fade-up">
                            <img class="radius w-100" :src="require('@/assets/images/'+lang+'/embedded5.png')" alt="Images" height="100px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>
        <div class="our-counterup-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail" data-aos="fade-up">
                            <img class="radius w-100" :src="require('@/assets/images/'+lang+'/embedded6.png')" alt="Images" height="100px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>
        <div class="our-counterup-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail" data-aos="fade-up">
                            <img class="radius w-100" :src="require('@/assets/images/'+lang+'/embedded7.png')" alt="Images" height="100px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from "../components/common/Layout";
    import Separator from "../components/elements/separator/Separator";
    export default {
        name: 'WebAgency',
        components: { Separator, Layout},
        data() {
            return {
            }
        },
        computed: {
            lang(){
                return this.$i18n.locale;
            }
        }
    }
</script>