<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="false" :show-top-header="false" :logo-dark="true"
        buy-button-class="btn-default btn-small">
        <!-- Start Error-Area Area  -->
        <div class="error-area ptb--100 ptb_sm--60 ptb_md--80">
            <div class="container">
                <div class="row align-item-center">
                    <div class="col-lg-12">
                        <div class="error-inner">
                            <!-- <h1>404</h1> -->
                            <h2 class="title" style="margin-bottom: 60px">{{ $t('deleteAppAccounut') }}</h2>
                            <div>
                                <div class="ptb--10 ptb_sm--5 ptb_md--8">
                                {{$t('ifDeleteTipsOne')}}
                                </div>
                                <div class="ptb--10 ptb_sm--5 ptb_md--8">
                                   {{$t('ifDeleteTipsTwo')}}
                                </div>
                                <div class="ptb--10 ptb_sm--5 ptb_md--8">
                                   {{$t('ifDeleteTipsThree')}}
                                </div>

                            </div>
                            <form class="mt--40" action="#">
                               
                                    <!-- <div class="col-lg-6 col-md-12 col-12"> -->
                                        <div class="rnform-group">
                                            <input type="text" v-model="email" :placeholder="$t('inputEmailTip')">
                                        </div>
                                        <div class="rnform-group">
                                            <input type="email" v-model="password"  :placeholder="$t('inputPassTip')">
                                        </div>
                                    <!-- </div> -->
                                    
                                    <div class="col-lg-12">
                                        <div class="blog-btn" @click="submit">
                                            <a class="btn-default" style="cursor:pointer">
                                                <span> {{$t('submit')}} </span>
                                            </a>
                                        </div>
                                    </div>
                               
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <!-- End Error-Area Area  -->
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import { Message } from 'element-ui';

import axios from 'axios';

export default {
    components: {
        Layout
    },
    data(){
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        submit(){

            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(this.email)) {
                alert(this.$t('errorEmail'))
                return;
            }

             if (this.password.length < 6) {
                alert(this.$t('errorPasss'))
                return;
            }

            this.save();
        },

        async save() {
            let lang = "en";
            if (this.$i18n.locale == "zh") {
                lang = "zh_cn"
            }

            axios.post('https://fl.fitshow.com/api/auth/saveAccountInfo?lang='+lang, {
                account: this.email,
                password: this.password,
            })
            .then(function (response) {
                console.log(response);
                if (response.data.code == 1) {
                    alert(this.$t('subSuccess'))
                } else {
                    alert(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>