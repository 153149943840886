<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon">
                    <Icon :name="service.icon" :class="`size-${iconSize}`"/>
                </div>
                <div class="content">
                    <h4 class="title w-600">
                        {{service.title}}
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="service.description"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'ServiceOne',
        components: {Icon},
        props: {
            serviceStyle: {
                type: String
            },
            iconSize: {
                type: String|Number,
                default: 62
            },
            textAlign: {
                type: String,
                default: 'left'
            },
            serviceList: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
            }
        },
        computed: {
            // serviceList() {
            //     return [
            //         {
            //             icon: 'activity',
            //             title: this.$t('hardwareDevelopment'),
            //             description: this.$t('hardwareDevelopmentDetail'),
            //         },
            //         {
            //             icon: 'cast',
            //             title: this.$t('softwareDevelopment'),
            //             description: this.$t('softwareDevelopmentDetail'),
            //         },
            //         {
            //             icon: 'map',
            //             title: this.$t('appServices'),
            //             description: this.$t('appServicesDetail1'),
            //         },
            //     ]
            // },
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>