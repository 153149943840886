import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import 'bootstrap'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueTypedJs from 'vue-typed-js'
import i18n from './lang/index';
import router from './router'

import apis from '@/http/request';
Vue.prototype.apis = apis;

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)

// router.beforeEach((to,from,next)=>{
//   if (!to.meta.url) return next();
//   const reg = /^[http|https]/
//   if (reg.test(to.meta.url)) {
//     window.location.href = to.meta.url 
//   } else {
//     next();
//   }
// })


var vm = new Vue({
  router,
  i18n,
  created () {
    this.$router.options.routes.forEach(e => e.meta.title = this.$t(e.meta.title))
    document.title = this.$t(this.$route.meta.title);
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App)
}).$mount('#app')
