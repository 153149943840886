<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false"
        buy-button-class="btn-default btn-small">
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting" autoplay :autoplaySpeed="autoplaySpeed" :pauseOnHover="false"
                :pauseOnDotsHover="true">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                        :style="{ 'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})` }">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="order-2 order-lg-1 col-lg-7">
                                    <div class="inner text-start">
                                        <h1 class="title" v-html="slider.title" />
                                        <p class="description" v-html="slider.description" />
                                        <div class="button-group mt--30">
                                            <a class="btn-default" href="#">
                                                {{$t('learnMore')}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>

        <!-- <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" title="Services provide for you."
                            description="FitShow's services include smart hardware development, APP Services, <br /> and software custom development services."
                            data-aos="fade-up" />
                    </div>
                </div>
                <ServiceFive service-style="gallery-style" text-align="left" />
            </div>
        </div> -->

        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :title="$t('servicesProvideForYou')"
                            :description="$t('servicesProvideForYouDetail')"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </div>
        <Separator />

        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" :title="$t('ourProducts')" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15" data-aos="fade-up">
                    <template v-for="(portfolio, index) in portfolioData">
                        <div :class="`col-lg-4 col-md-6 col-12 mt--30 portfolio aos-init aos-animate`" data-aos="fade-up">

                            <div class="rwt-card" data-aos="fade-up">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <figure class="card-image">
                                            <img class="img-fluid" :src="portfolio.portfolioImage" alt="Portfolio-01" />
                                        </figure>
                                    </div>
                                    <div class="content">
                                        <h5 class="title mb--10">
                                            {{ portfolio.title }}
                                        </h5>
                                        <span class="subtitle b2 text-capitalize">{{ portfolio.category }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>


        <Separator />

        <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="../../assets/images/about/about-1.png" alt="About Images">
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <SectionTitle :title="$t('softwareDevelopment')" data-aos="fade-up" />
                            <accordion id="accordionExampleOne" :accordion-content="[
                                {
                                    id: 'One',
                                    title: $t('allInOneApp'),
                                    description: $t('allInOneAppDetail'),
                                },
                                {
                                    id: 'Two',
                                    title: $t('brandService'),
                                    description: $t('brandServiceDetail'),
                                },
                                {
                                    id: 'Three',
                                    title: $t('oemAPP'),
                                    description: $t('oemAPPDetail'),
                                },
                                {
                                    id: 'Four',
                                    title: $t('fitnessAppDevelopment'),
                                    description: $t('fitnessAppDevelopmentDetail'),
                                },
                            ]" data-aos="fade-up" data-aos-delay="60" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Separator />
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" :title="$t('appServices')" data-aos="fade-up" data-aos-delay="60" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" />
                    </div>
                </div>
            </div>
        </div>

        <Separator />

        <section class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle class="mb--50" text-align="center" :title="$t('servicesByNumbers')" data-aos="fade-up"
                            data-aos-delay="60" />
                    </div>
                </div>
                <Counter :counter-style="5" text-align="center" :counter-data="counterFiveData"
                    column="col-lg-3 col-md-6 col-sm-6 col-12" />
            </div>
        </section>

        <Separator />

        <div class="rwt-brand-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" :title="$t('powersCustomersWorldwide')"
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row" >
                    <div class="col-lg-12 mt--40" data-aos="fade-up">
                        <Brand :brand-list="brandList" />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import Separator from '../../components/elements/separator/Separator'
import VueSlickCarousel from 'vue-slick-carousel'
import BlogPost from '../../components/blog/BlogPost'
import BlogPostMixin from '../../mixins/BlogPostMixin'
import Testimonial from '../../components/elements/testimonial/Testimonial'
import Portfolio from '../../components/elements/portfolio/Portfolio'
import ServiceFive from '../../components/elements/service/ServiceFive'
import Progressbar from '../../components/elements/progressbar/Progressbar'
import Timeline from "../../components/elements/timeline/Timeline";
import Accordion from '../../components/elements/accordion/Accordion'
import Counter from "../../components/elements/counterUp/Counter";
import Brand from "../../components/elements/brand/Brand";
import ServiceOne from '../../components/elements/service/ServiceOne'
import { RouterLink } from 'vue-router'


export default {
    name: 'DigitalAgency',
    components: {
    Progressbar,
    ServiceFive,
    Portfolio,
    Testimonial,
    BlogPost,
    Separator,
    SectionTitle,
    Layout,
    Accordion,
    Timeline,
    VueSlickCarousel,
    Counter,
    Brand,
    ServiceOne,
    RouterLink
},
    mixins: [BlogPostMixin],
    data() {
        return {
            autoplaySpeed: 4000,
        }
    },
    computed: {
        sliderSetting() {
            return {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 993,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 580,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 481,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            }
            },
            sliderData() {
                return [
                {
                    image: 'fitshow-title-back-image-2',
                    title: this.$t('smartHardware'),
                    description: this.$t('smartHardwareDetail'),
                },
                {
                    image: 'fitshow-title-back-image-3',
                    title: this.$t('appService'),
                    description: this.$t('appServiceDetail'),
                },
                {
                    image: 'fitshow-title-back-image-4',
                    title: this.$t('appCustom'),
                    description: this.$t('appCustomDetail'),
                },
                {
                    image: 'fitshow-title-back-image-1',
                    title: this.$t('smartMadeEasy'),
                    description: this.$t('smartMadeEasyDetail'),
                },
            ]
            },

            timelineData(){
                return [
                {
                    id: '1',
                    title: this.$t('fitshowApp'),
                    description: this.$t('fitshowAppDetail'),
                    image: 'timeline-01',
                    workingStep: [
                        {
                            stepTitle: this.$t('originalApp'),
                            stepDescription: this.$t('originalAppDetail'),
                        },
                        {
                            stepTitle: this.$t('brandingServices'),
                            stepDescription: this.$t('brandingServicesDetail'),
                        },
                    ],
                    showButton: false,
                },
                {
                    id: '2',
                    title: this.$t('thirdPartyApp'),
                    description: this.$t('thirdPartyAppDetail'),
                    image: 'timeline-02',
                    showButton: false,
                },
            ]
            },
            
            portfolioData() {
                return [
                {
                    id: 1,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    title: this.$t('bluetoothModule'),
                    category: this.$t('bluetoothModuleDetail'),
                },
                {
                    id: 2,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    title: this.$t('smartKnob'),
                    category: this.$t('smartKnobDetail'),
                },
                {
                    id: 3,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    title: this.$t('wirelessController'),
                    category: this.$t('wirelessControllerDetail'),
                },
                {
                    id: 4,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                    title: this.$t('sisplaySystem'),
                    category: this.$t('sisplaySystemDetail'),
                },
                {
                    id: 5,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                    title: this.$t('heartRateMonitor'),
                    category: this.$t('heartRateMonitorDetail'),
                },
                {
                    id: 6,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                    title: this.$t('resistanceRegulator'),
                    category: this.$t('resistanceRegulatorDetail'),
                },

            ]
            },
            counterFiveData() {
                return  [
                {
                    number: 5000000,
                    title: this.$t('smartEnabledDevices'),
                },
                {
                    number: 280,
                    title: this.$t('oemPartners'),
                },
                {
                    number: 220,
                    title: this.$t('brandPartners'),
                },
                {
                    number: 120,
                    title: this.$t('regionalDistribution'),
                }
            ]
            },
            brandList() {
                return  [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`),
                        link: "https://bh.fitness/"
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`),
                        link: "https://www.hammer.de/de-en/home  "
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`),
                        link: "https://www.sportstech.de/"
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`),
                        link: "https://www.diadora.com/homepage"
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`),
                        link: "https://www.attacusfitness.com/index"
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`),
                        link: "http://www.yeekang-sports.com/"
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`),
                        link: "https://www.asviva.de/"
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`),
                        link: "https://trojanhealth.co.za/"
                    }
                ]
            },
    }
}
</script>