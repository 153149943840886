const apis = [
     // {
  //   name: "test", // 接口调用方法名
  //   path: '/api/test', // 后端路径
  //   method: "POST", // 默认POST
  //   headers: {"token": 123}, // 自定义单个接口的请求头
  //   desc: "登录接口", // 接口描述
  // },
    {
        name: "getArticle",
        path: "/api/articleLibrary/get",
    },
    {
        name: "saveAccoutInfo",
        path: "/api/auth/saveAccoutInfo",
        method: "POST",
        data: "data"
    },
    (data) => {
        return {
            name: "saveAccoutInfo",
            path: "/api/auth/saveAccoutInfo",
            method: "POST",
            data: data
        }
    }
]

export default apis;